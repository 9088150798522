


















import Class from './c-indicator-select';
export default Class;
