import { Component, Prop, Vue } from 'vue-property-decorator';

import { I18nTemplate } from '@/services/i18n';
import Multiselect from 'vue-multiselect';

import '../../../initialization';
import { accessor } from '../../../store';
import { IIndicator, IWidgetTextLines, IWidgetTextBars, IWidgetMeters, IWidgetChartLinearMarkers, IWidgetChart3dPie, IWidgetChartFlatPie, IWidgetChartRadiusPie, IWidgetChartStackColumn, IWidgetChartTreeMap, IWidgetChartActual, IWidgetChartAmCharts } from '../../../store/types';

import CIndicatorSelect from '../c-indicator-select';


interface IIndicatorOption {
    value: string;
    text: string;
    disabled?: boolean;
    title: string;
    oldValue: string;
}

type IWidgets = IWidgetTextLines | IWidgetTextBars | IWidgetMeters | IWidgetChartLinearMarkers | IWidgetChart3dPie | IWidgetChartFlatPie | IWidgetChartRadiusPie | IWidgetChartStackColumn | IWidgetChartTreeMap | IWidgetChartActual | IWidgetChartAmCharts;

@Component({
    components: {
        'c-indicator-select': CIndicatorSelect,
        'multiselect': Multiselect
    }
})
export default class CConfig extends Vue {
    public t(key: string, ...args: string[]): string {
        return new I18nTemplate(`modules.widgetYard.tabs.constructor.widgetTypes.common.${key}`, ...args).toString();
    }

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: any;

    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly max!: number;


    private get indicators(): IIndicator[] {
        return accessor.indicators;
    }

    private get indicatorsOptions(): IIndicatorOption[] {
        const result: IIndicatorOption[] = [];
        this.indicators.forEach((indicator, i) => {
            result.push({
                value: indicator.key,
                text: `${indicator.withPlan ? '+' : ''} ${indicator.indicator} (${indicator.periodicity})`,
                /* text: `${i + 1}. ${indicator.withPlan ? '+' : ''} ${indicator.indicator} (${indicator.file})`, */
                title: indicator.shortIndicator,
                oldValue: indicator.newKey
            });
        });
        return result;
    }

    public get indicatorConfigs() {
        if ((this.data.dictCategoryEntity === undefined) || (this.data.dictCategoryEntity === {})) {
            this.data.dictCategoryEntity = null;
        }
        const configs = this.data.indicatorConfigs;
        this.getUnits();
        configs.forEach((config: any) => {
            if (config.measurement === undefined) {
                config.measurement = 1;
            }
            if (config.unit === undefined) {
                config.unit = {};
            }
            if (config.periodicity === undefined) {
                config.periodicity = this.indicatorPeriodicity(config.indicator);
            }
        });
        if (this.data.updateDate === undefined) {
            this.data.updateDate = null;
        }
        return this.data.indicatorConfigs;
    }

    public set indicatorConfigs(value) {
        this.data.indicatorConfigs = value;
    }

    private get inputText() {
        const titles: any[] = [];
        this.indicatorConfigs.forEach((config: any) => {
            const title = config.title;
            titles.push(title);
        });
        return titles;
    }


    private addIndicatorKeyClick() {
        if (this.indicatorConfigs.length <= this.max) {
            if (this.indicators.length > 0) {
                const newConfigs = Array.from(this.indicatorConfigs);
                const config = {
                    indicator: this.indicators[0].key,
                    title: this.indicators[0].indicator,
                    measurement: 1,
                    unit: {},
                    periodicity: this.indicators[0].periodicity
                };
                newConfigs.push(config);
                this.indicatorConfigs = newConfigs;
                this.data.size = [486, 348];
                this.data.updateDate = new Date().getTime();
            }
        }
    }

    private indicatorTitle(value: string) {
        let title = '';
        for (const indicator of this.indicatorsOptions) {
            if (value === indicator.value) {
                title = indicator.title;
                break;
            }
        }
        return title;
    }

    private indicatorPeriodicity(value: string) {
        let period = '';
        for (const indicator of this.indicators) {
            if (value === indicator.key) {
                period = indicator.periodicity;
                break;
            } else if (value === indicator.newKey) {
                period = indicator.periodicity;
                break;
            }
        }
        return period;
    }

    private indicatorPlan(value: string) {
        const selectedIndicator = accessor.selectedMap.get(value);
        if (selectedIndicator === undefined) {
            return undefined;
        }
        return selectedIndicator.withPlan;
    }

    private indicatorKeyChanged(index: number, key: string) {
        if ((index >= 0) && (index <= this.indicatorConfigs.length - 1)) {
            const newConfigs = Array.from(this.indicatorConfigs);
            const config: any = newConfigs[index];
            config.indicator = key;
            const title = this.indicatorTitle(key);
            config.title = title;
            config.measurement = 1;
            config.unit = {};
            config.periodicity = this.indicatorPeriodicity(key);
            this.indicatorConfigs = newConfigs;
            this.data.size = [486, 348];
            this.data.updateDate = new Date().getTime();
        }
    }

    private deleteIndicatorKeyClick(index: number) {
        if ((index >= 0) && (index <= this.indicatorConfigs.length - 1)) {
            const newKeys = Array.from(this.indicatorConfigs);
            newKeys.splice(index, 1);
            this.indicatorConfigs = newKeys;
        }
    }

    private get usedIndicators(): string[] {
        const usedList: string[] = [];
        this.indicatorConfigs.forEach((indicator: any) => {
            usedList.push(indicator.indicator);
        });
        return usedList;
    }

    private titleChanged(): void {
        setTimeout(() => {
            this.inputText.forEach((text, i) => {
                this.indicatorConfigs[i].title = text;
            });
            this.data.updateDate = new Date().getTime();
        }, 2);
    }

    private update() {
        this.indicatorConfigs = Array.from(this.indicatorConfigs);
    }

    private units: any = [];

    private async getUnits() {
        let list: any[] = [];
        await fetch('/api/stat-dict/unit')
            .then(response => response.json())
            .then(json => (list = json));
        this.units = list;
        const first = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            name_ru: ''
        };
        this.units.unshift(first);
    }

    private get sourceUnit() {
        const sourceUnits: any[] = [];
        // eslint-disable-next-line consistent-return
        this.indicatorConfigs.forEach((config: any) => {
            const indicatorKey = config.indicator;
            const indicator = accessor.getIndicator(indicatorKey);
            if (indicator === undefined) {
                return null;
            }
            const unit = indicator.unit;
            sourceUnits.push(unit);
        });
        return sourceUnits;
    }
}