



























































































































import Class from './c-config-pane';
export default Class;
