
































import { Component, Vue } from 'vue-property-decorator';

import CConstructorTab from './c-contructor-tab';
import CDataTab from './c-data-tab';
import CWidgetDate from './c-widget-date';
import './initialization';

@Component({
    components: {
        'c-constructor-tab': CConstructorTab,
        'c-data-tab': CDataTab,
        'c-widget-date': CWidgetDate
    }
})
export default class Class extends Vue {
    private tab = 0;
    private all = 'all';
    private chartActual = 'chart-actual';
}
