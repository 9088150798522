













































































import Class from './c-content-char-bars';
export default Class;
