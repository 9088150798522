import { Component, Vue, Prop } from 'vue-property-decorator';
import VueDraggable from 'vuedraggable';

import '../initialization';
import { accessor, bridge } from '../store';
import { EWidgetType, IWidgetTextLines, TWidgetLoadState, TWidget, IWidgetChartActual } from '../store/types';

import CWidget from './c-widget';
import CWidgetView from '../c-widget';
import { exporting } from '@/modules/soc-mod/export-widget';
import VueElementLoading from 'vue-element-loading';
import { Ax } from '@/utils';

/* import ModalHideEvent from '@/components/c-modal/modal-hide-event'; */


@Component({
    components: {
        'c-widget': CWidget,
        'c-widget-view': CWidgetView,
        'vue-draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class CConstructorTab extends Vue {
    @Prop({
        type: String,
        required: true
    })
    public readonly type!: string | undefined;


    private scrollToWidget() {
        const widgetId = Number(this.$route.query.widgetId);

        if (widgetId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
            const editWidget: any = this.$refs['widget-id-' + widgetId][0].offsetTop;

            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const widgetList: object = this.$refs['widget-list'];

            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            widgetList.scrollBy({
                top: editWidget,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    private loading = false;

    private selectedIndex = -1;


    private windowResized() {
        this.updateCollapsible();
    }

    private async created() {
        const self = this;
        const loadingState = setInterval(function () {
            if (accessor.indicators.length === 0) {
                self.loading = true;
            } else {
                clearInterval(loadingState);
                self.loading = false;
            }
        }, 1000);
        this.$watch('widgets', this.widgetsChanged);
        this.$watch('collapsible', this.collapsibleChanged);
        await this.getCategory();
    }

    private updated() {
        // this.scrollToWidget();
    }

    private mounted() {
        window.addEventListener('resize', this.windowResized);
        this.windowResized();

        if (Object.keys(this.$route.query).length > 0) {
            if (this.$route.query.widgetId !== null) {
                const widgetId = Number(this.$route.query.widgetId);
                let newIndex = 0;
                let i = 0;
                this.widgets.forEach((w) => {
                    if (w.id === widgetId) {
                        newIndex = i;
                    }
                    i++;
                });
                this.selectedIndex = newIndex;
            }
        }
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.windowResized);
    }

    private debug = false;

    private get widgetsLoadState(): TWidgetLoadState {
        return accessor.widgetsLoadState;
    }

    private saveWidgetsClick() {
        accessor.saveWidgets();
    }

    private widgetIdLoading = false;

    private addWidgetClick() {
        this.widgetIdLoading = true;
        if (this.type === 'chart-actual') {
            bridge.nextWidgetId(
                (id) => {
                    const widget: IWidgetChartActual = {
                        id,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        type: EWidgetType.CHART_ACTUAL,
                        size: [],
                        updateDate: new Date().getTime()
                    };

                    accessor.addWidget(widget);
                    this.selectedIndex = 0;
                },
                () => {
                    this.widgetIdLoading = false;
                }
            );
        } else {
            bridge.nextWidgetId(
                (id) => {
                    const widget: IWidgetTextLines = {
                        id,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        type: EWidgetType.TEXT_LINES,
                        size: [496, 348],
                        updateDate: new Date().getTime()
                    };

                    accessor.addWidget(widget);
                    this.selectedIndex = 0;
                },
                () => {
                    this.widgetIdLoading = false;
                }
            );
        }
    }

    private collapsible = false;
    private widgetListCollapsed = false;
    private widgetPropertiesCollapsed = false;
    private showModal = false;
    private showUpdate = false;

    private updateCollapsible() {
        const collapsible = window.innerWidth < 1200;
        if (this.collapsible !== collapsible) {
            this.collapsible = collapsible;
        }
    }

    private collapsibleChanged() {
        if (this.collapsible) {
            if (!this.widgetListCollapsed) {
                this.widgetListCollapsed = true;
            }
            if (!this.widgetPropertiesCollapsed) {
                this.widgetPropertiesCollapsed = true;
            }
        } else {
            if (this.widgetListCollapsed) {
                this.widgetListCollapsed = false;
            }
            if (this.widgetPropertiesCollapsed) {
                this.widgetPropertiesCollapsed = false;
            }
        }
    }

    private widgetListToggleClick() {
        if (this.collapsible) {
            if (!this.widgetPropertiesCollapsed) {
                this.widgetPropertiesCollapsed = true;
            }
            this.widgetListCollapsed = !this.widgetListCollapsed;
        }
    }

    private widgetPropertiesToggleClick() {
        if (this.collapsible) {
            if (!this.widgetListCollapsed) {
                this.widgetListCollapsed = true;
            }
            this.widgetPropertiesCollapsed = !this.widgetPropertiesCollapsed;
        }
    }

    private widgetPreviewOverlayClick() {
        if (this.collapsible) {
            if (!this.widgetListCollapsed) {
                this.widgetListCollapsed = true;
            }
            if (!this.widgetPropertiesCollapsed) {
                this.widgetPropertiesCollapsed = true;
            }
        }
    }

    private get widgets(): TWidget[] {
        return accessor.widgets;
    }

    private set widgets(widgets: TWidget[]) {
        if ((this.selectedIndex >= 0) && (this.selectedIndex < this.widgets.length)) {
            const selectedItem = this.widgets[this.selectedIndex];
            const newIndex = widgets.indexOf(selectedItem);
            if (newIndex === -1) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex = newIndex;
            }
        }

        accessor.widgets = widgets;
    }

    private widgetsChanged() {
        const widgets = this.widgets;
        if (widgets.isEmpty) {
            if (this.selectedIndex !== -1) {
                this.selectedIndex = -1;
            }
        } else {
            if (this.selectedIndex < 0) {
                this.selectedIndex = 0;
            } else if (this.selectedIndex >= widgets.length) {
                this.selectedIndex = widgets.length - 1;
            }
        }
    }

    private widgetItemClick(index: number) {
        if (this.collapsible) {
            if (!this.widgetPropertiesCollapsed) {
                this.widgetPropertiesCollapsed = true;
            }
            this.widgetListCollapsed = true;
        }

        if ((index >= 0) && (index < this.widgets.length)) {
            this.selectedIndex = index;
        }
    }

    private onDeleteClick() {
        if ((this.selectedIndex >= 0) && (this.selectedIndex < this.widgets.length)) {
            const widget = this.widgets[this.selectedIndex];
            const id = widget.id;
            accessor.deleteWidget(id);
            accessor.saveWidgets();
        }
    }

    private exportWidgets: any[] = [];

    private clickDownload(dev: string) {
        this.loading = true;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        setTimeout(function () {
            const form = {
                'source': that.$store.state._instanceCode,
                'widgets': that.exportWidgets,
                'dev': dev
            };
            that.sendToReport(form);
            that.exportDone = true;
        }, 500);
    }

    private exportDone = false;

    private async getCategory() {
        let lists: any[] = [];
        const category: any[] = [];
        await fetch('/api/dict/integration_category')
            .then(response => response.json())
            .then(json => (lists = json));
        lists.sort((a: any, b: any) => a.code.split('.').length - b.code.split('.').length);
        for (const list of lists) {
            list.code = list.code.replace(' \t', '');
            if (list.code.split('.')[0] === '02') {
                list.children = [];
                if (list.parent !== null) {
                    lists.forEach((categ: any) => {
                        if (categ.children !== undefined) {
                            if (categ.id === list.parent) {
                                categ.children.push(list);
                            }
                        }
                    });
                }
            }
            if (list.id === 231) {
                category.push(list);
            }
        }
        const widgets = this.getWidgets();
        for (const list of lists) {
            list.code = list.code.replace(' \t', '');
            if (list.code.split('.')[0] === '02') {
                list.widgets = [];
                // eslint-disable-next-line no-await-in-loop
                for (const widget of await widgets) {
                    if ((widget.dictCategoryEntity !== undefined) && (widget.dictCategoryEntity !== null)) {
                        if (widget.dictCategoryEntity.index === undefined) {
                            widget.dictCategoryEntity.index = widget.id;
                        }
                        if (list.id === widget.dictCategoryEntity.id) {
                            list.widgets.push(widget);
                        }
                    }
                }
            }
        }
        category[0].children.forEach((list: any) => {
            if (list.children.length > 0) {
                for (const child of list.children) {
                    if (child.children.length > 0) {
                        for (const childish of child.children) {
                            if (childish.widgets.length > 0) {
                                childish.widgets.forEach((widget: any) => {
                                    child.widgets.push(widget);
                                });
                            }
                        }
                    }
                    if (child.widgets.length > 0) {
                        child.widgets.forEach((widget: any) => {
                            list.widgets.push(widget);
                        });
                    }
                }
            }
            const children: any[] = [];
            const postChildren: any[] = [];
            if (list.children.length > 0) {
                list.children.forEach((child: any) => {
                    if (child.widgets.length > 0) {
                        children.push(child);
                        if (child.children.length > 0) {
                            child.children.forEach((childish: any) => {
                                if (childish.widgets.length > 0) {
                                    postChildren.push(childish);
                                }
                            });
                        }
                    }
                });
            }
            if (list.widgets.length > 0) {
                list.widgets.sort((a: any, b: any) => a.dictCategoryEntity.index - b.dictCategoryEntity.index);
                if (children.length > 0) {
                    if (postChildren.length > 0) {
                        this.menuSocMods.push({
                            name: list.name_ru,
                            id: list.id,
                            code: list.code,
                            widgets: list.widgets,
                            children: children,
                            postChildren: postChildren
                        });
                    } else {
                        this.menuSocMods.push({
                            name: list.name_ru,
                            id: list.id,
                            code: list.code,
                            widgets: list.widgets,
                            children: children
                        });
                    }
                } else {
                    this.menuSocMods.push({
                        name: list.name_ru,
                        id: list.id,
                        code: list.code,
                        widgets: list.widgets
                    });
                }
            }
        });
        this.menuSocMods.forEach((item: any) => {
            const itemes = {
                'code': item.code,
                'name': item.name,
                'widgets': item.widgets,
                'chart': []
            };
            this.exportWidgets.push(itemes);
        });
        this.exportWidgets.sort((a: any, b: any) => parseInt(a.code.split('.')[1]) - parseInt(b.code.split('.')[1]));
        this.exportWidgets.shift();
    }

    private async getWidgets() {
        let lists: any[] = [];
        await fetch('/api/datas/hierarchy')
            .then(response => response.json())
            .then(json => (lists = json));
        this.allWidgets = lists;
        return lists;
    }

    private allWidgets: any = [];

    private menuSocMods: any[] = [];

    private sendToReport(config: any) {
        // eslint-disable-next-line no-console
        console.log(config);
        Ax(
            {
                url: '/api-py/widget-export',
                method: 'POST',
                data: config
            },
            (data: any) => {
                // eslint-disable-next-line no-console
                console.log(data);
                this.loading = false;
            },
            (error: any) => {
                // eslint-disable-next-line no-console
                console.log(error);
                this.loading = false;
                // eslint-disable-next-line no-alert
                alert('Пожалуйста, обновите страницу и попробуйте снова');
            }
        );
    }
}