import { Component, Vue, Prop } from 'vue-property-decorator';

import '../../initialization';
import { EWidgetType, IWidgets } from '../../store/types';
import { I18nTemplate } from '@/services/i18n';
import { accessor } from '../../store';
import Multiselect from 'vue-multiselect';

import CConfigPane from './c-config-pane';
import CContentChartBars from './c-content-char-bars';
import CContentChartMultipleAxes from './c-content-chart-multiple-axes';
import CContentChartBudget from './c-content-chart-budget';
import CContentChartModelData from './c-content-chart-model-data';


interface ITypeOption {
    value: EWidgetType;
    text: I18nTemplate;
    disabled?: boolean;
}

const options: ITypeOption[] = [
    {
        value: EWidgetType.TEXT_LINES,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.textLines.title')
    },
    {
        value: EWidgetType.TEXT_BARS,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.textBars.title')
    },
    {
        value: EWidgetType.METERS,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.meters.title')
    },
    {
        value: EWidgetType.CHART_BARS,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartBars.title')
    },
    {
        value: EWidgetType.CHART_BUDGET,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartBudget.title')
    },
    {
        value: EWidgetType.CHART_LINEAR_MARKERS,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartLinearMarkers.title')
    },
    {
        value: EWidgetType.CHART_3D_PIE,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chart3dPie.title')
    },
    {
        value: EWidgetType.CHART_FLAT_PIE,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartFlatPie.title')
    },
    {
        value: EWidgetType.CHART_RADIUS_PIE,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartRadiusPie.title')
    },
    {
        value: EWidgetType.CHART_MULTIPLE_AXES,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartMultipleAxes.title')
    },
    {
        value: EWidgetType.CHART_STACK_COLUMN,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartStackColumn.title')
    },
    {
        value: EWidgetType.CHART_TREE_MAP,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartTreeMap.title')
    },
    {
        value: EWidgetType.CHART_ACTUAL,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartActual.title')
    },
    {
        value: EWidgetType.AM_CHARTS,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartAmCharts.title')
    },
    {
        value: EWidgetType.CHART_GANTT,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartGantt.title')
    },
    {
        value: EWidgetType.CHART_MODEL_DATA,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartModelData.title')
    },
    {
        value: EWidgetType.CHART_MODEL_LINEAR,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartModelLinear.title')
    },
    {
        value: EWidgetType.CHART_COVID,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartCovid.title')
    },
    {
        value: EWidgetType.CHART_VACCINATION,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartVaccination.title')
    },
    {
        value: EWidgetType.CHART_SOC,
        text: new I18nTemplate('modules.widgetYard.tabs.constructor.widgetTypes.chartSoc.title')
    }
];
Object.freeze(options);


@Component({
    components: {
        'c-config-pane': CConfigPane,
        'c-content-char-bars': CContentChartBars,
        'c-content-chart-multiple-axes': CContentChartMultipleAxes,
        'c-content-chart-budget': CContentChartBudget,
        'c-content-chart-model-data': CContentChartModelData,
        'multiselect': Multiselect
    }
})
export default class CWidget extends Vue {
    public readonly typeOptions = options;


    @Prop({
        type: Object,
        required: true
    })
    public data!: IWidgets;

    public get id(): number {
        return this.data.id;
    }

    public get type(): any {
        return this.data.type;
    }

    public set type(value: any) {
        this.data.type = value;
    }

    public get typeTitle(): string {
        const type = this.type;
        const found = this.typeOptions.find((option): ITypeOption | undefined => {
            if (option.value === type) {
                return option;
            }
            return undefined;
        });

        if (found !== undefined) {
            return found.text.toString();
        }

        return '???';
    }

    public get title(): string {
        this.getCategory();
        return this.data.title;
    }

    public set title(value: string) {
        this.data.title = value;
    }


    private deleteClick() {
        accessor.deleteWidget(this.id);
    }

    private async getCategory() {
        let list: any[] = [];
        await fetch('/api/dict/integration_category')
            .then(response => response.json())
            .then(json => (list = json));
        const lists = list;
        const category: any[] = [];
        lists.forEach((categ: any) => {
            categ.children = [];
            list.forEach((item) => {
                if (categ.children !== undefined) {
                    if (categ.id === item.parent) {
                        categ.children.push(item);
                    }
                }
            });
        });
        for (const item of lists) {
            if (item.code === '01' || item.code === '02') {
                category.push(item);
            }
        }
        this.sortCategory(category);
    }

    private categoryList: any[] = [];

    private sortCategory(category: any) {
        category.sort((a: any, b: any) => a.code.split('.')[a.code.split('.').length - 1] - b.code.split('.')[b.code.split('.').length - 1]);
        category.forEach((item: any) => {
            this.categoryList.push(item);
            if (item.children.length > 0) {
                this.sortCategory(item.children);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    private customLabel({ code, name_ru }: any) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return `[${code}] ${name_ru}`;
    }
}