import { Component, Vue } from 'vue-property-decorator';

import '../initialization';
import { accessor } from '../store';
import { ISelectedIndicatorItems } from '../store/types';

@Component
export default class CDataTab extends Vue {
    private expanded: Set<string> = new Set();

    private isExpanded(key: string): boolean {
        return this.expanded.has(key);
    }

    private expand(key: string) {
        if (!this.expanded.has(key)) {
            const newExpanded = new Set(this.expanded);
            newExpanded.add(key);
            this.expanded = newExpanded;
        }
    }

    private collapse(key: string) {
        if (this.expanded.has(key)) {
            const newExpanded = new Set(this.expanded);
            newExpanded.delete(key);
            this.expanded = newExpanded;
        }
    }

    private toggleCollapse(key: string) {
        if (this.isExpanded(key)) {
            this.collapse(key);
        } else {
            this.expand(key);
        }
    }

    private get selectedItems(): ISelectedIndicatorItems[] {
        return accessor.selectedArray;
    }
}