


























































































































import Class from './c-data-tab';
export default Class;
