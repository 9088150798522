import { Component, Prop, Vue } from 'vue-property-decorator';
import VueDraggable from 'vuedraggable';
import CIndicatorSelect from '../c-indicator-select';

import '../../../initialization';
import { accessor } from '../../../store';
import { IIndicator, IWidgetChartBudget } from '../../../store/types';



interface IIndicatorOption {
    value: string;
    text: string;
    disabled?: boolean;
    title: string;
    plan: boolean | undefined;
}


@Component({
    components: {
        'vue-draggable': VueDraggable,
        'c-indicator-select': CIndicatorSelect
    }
})
export default class CContentChartBudget extends Vue {
    private get indicators(): IIndicator[] {
        return accessor.indicators;
    }

    private get indicatorsOptions(): IIndicatorOption[] {
        const result: IIndicatorOption[] = [];

        this.indicators.forEach((indicator, i) => {
            result.push({
                value: indicator.key,
                text: `${indicator.withPlan ? '+' : ''} ${indicator.indicator} (${indicator.periodicity})`,
                /* text: `${i + 1}. ${indicator.withPlan ? '+' : ''} ${indicator.indicator} (${indicator.file})`, */
                title: indicator.shortIndicator,
                plan: indicator.withPlan
            });
        });

        return result;
    }

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartBudget;

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    public set indicatorConfigs(value) {
        this.data.indicatorConfigs = value;
    }

    private addIndicatorKeyClick() {
        if (this.indicatorConfigs.length < 10) {
            if (this.indicators.length > 0) {
                const newConfigs = Array.from(this.indicatorConfigs);
                const config = {
                    indicator: this.indicators[0].key,
                    type: 'column',
                    title: this.indicators[0].indicator,
                    plan: this.indicators[0].withPlan
                };
                newConfigs.push(config);
                this.indicatorConfigs = newConfigs;
                if (this.indicatorConfigs.length <= 1) {
                    this.data.title = config.title;
                } else {
                    this.data.title = '';
                }
            }
        }
    }

    private indicatorTitle(value: string) {
        let title = '';
        for (const indicator of this.indicatorsOptions) {
            if (value === indicator.value) {
                title = indicator.title;
                break;
            }
        }
        return title;
    }

    private indicatorPlan(value: string) {
        const selectedIndicator = accessor.selectedMap.get(value);
        if (selectedIndicator === undefined) {
            return undefined;
        }
        return selectedIndicator.withPlan;
    }

    private indicatorKeyChanged(index: number, key: string) {
        if ((index >= 0) && (index <= this.indicatorConfigs.length - 1)) {
            const newConfigs = Array.from(this.indicatorConfigs);
            const config = newConfigs[index];
            config.indicator = key;
            const title = this.indicatorTitle(key);
            let plan = this.indicatorPlan(key);
            if (!plan) {
                plan = undefined;
            }
            config.title = title;
            this.indicatorConfigs = newConfigs;
            this.indicatorConfigs[0].plan = plan;
            if (this.indicatorConfigs.length <= 1) {
                this.data.title = config.title;
            }
        }
    }

    private deleteIndicatorKeyClick(index: number) {
        if ((index >= 0) && (index <= this.indicatorConfigs.length - 1)) {
            const newKeys = Array.from(this.indicatorConfigs);
            newKeys.splice(index, 1);
            this.indicatorConfigs = newKeys;
        }
    }

    private get usedIndicators(): string[] {
        const usedList: string[] = [];
        this.indicatorConfigs.forEach((indicator) => {
            usedList.push(indicator.indicator);
        });
        return usedList;
    }

    private update() {
        this.indicatorConfigs = Array.from(this.indicatorConfigs);
    }
}