































































import Class from './c-content-chart-budget';
export default Class;
