





























































































































































































import Class from './c-contructor-tab';
export default Class;
