import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';


interface IIndicatorOption {
    value: string;
    text: string;
    disabled?: boolean;
    oldValue: string;
}


const events = {
    model: 'change'
};

@Component({
    components: {
        'multiselect': Multiselect
    }
})
export default class CIndicatorSelect extends Vue {
    private created() {
        this.$watch('availableOptions', this.availableOptionsChanged);
    }


    @Prop({
        type: Array,
        required: true
    })
    public readonly indicators!: IIndicatorOption[];


    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly usedKeys!: string[];


    @Model(events.model, {
        type: String,
        required: true
    })
    public readonly value!: string;


    public get usedIndicator(): IIndicatorOption | null {
        const found = this.indicators.find((testing): IIndicatorOption | undefined => {
            if ((testing.value === this.value) || (testing.oldValue === this.value)) {
                return testing;
            }
            return undefined;
        });

        if (found === undefined) {
            return null;
        }
        return found;
    }

    public get usedIndicatorText(): string {
        if (this.usedIndicator === null) {
            return '';
        }
        return this.usedIndicator.text;
    }


    private filter = '';

    private get preparedFilter(): string {
        return this.filter.trim().toLowerCase();
    }

    private get filterParts(): string[] {
        const result = this.preparedFilter.split(' ');

        let i = 0;
        while (i < result.length) {
            const item = result[i].trim();
            if (item.length === 0) {
                result.splice(i, 1);
            } else {
                result.splice(i, 1, item);
                i++;
            }
        }

        return result;
    }

    private onClearFilter() {
        this.filter = '';
    }


    public get availableOptions(): IIndicatorOption[] {
        const result: IIndicatorOption[] = [];

        const filterHitCountMap = new Map<IIndicatorOption, number>();
        this.indicators.forEach((option) => {
            if (
                this.usedKeys.notIncludes(option.value)
                && (this.value !== option.value)
            ) {
                let filterPassed: boolean;
                if (this.filterParts.isEmpty) {
                    filterPassed = true;
                } else {
                    const optionText = option.text.toLowerCase();
                    let filterHitCount = 0;
                    for (const filterPart of this.filterParts) {
                        if (optionText.includes(filterPart)) {
                            filterHitCount++;
                        }
                    }

                    filterPassed = (filterHitCount > 0);
                    filterHitCountMap.set(option, filterHitCount);
                }

                if (filterPassed) {
                    result.push(option);
                }
            }
        });

        if (filterHitCountMap.size > 0) {
            result.sort((a, b): number => {
                const aNumber = filterHitCountMap.get(a) || 0;
                const bNumber = filterHitCountMap.get(b) || 0;
                return (bNumber - aNumber);
            });
        }

        return result;
    }

    private availableOptionsChanged() {
        if (this.bodyVisible) {
            this.bodyVisible = false;
            setTimeout(() => {
                this.bodyVisible = true;
            });
        }
    }


    private bodyVisible = false;

    private onItemClick(option: IIndicatorOption) {
        if (this.value !== option.value) {
            this.$emit(events.model, option.value);
            this.bodyVisible = false;
        }
    }
}